<template>
  <div>
    <validation-observer ref="simpleRules">
      <public />
      <license v-if="car.detailForm" />
      <other v-if="car.detailForm" />
      <save-button :action-methods="submitForm" />
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
// eslint-disable-next-line import/extensions
import Public from '@/views/Cars/components/Public'
// eslint-disable-next-line import/extensions
import License from '@/views/Cars/components/License'
// eslint-disable-next-line import/extensions
import Other from '@/views/Cars/components/Other'
// eslint-disable-next-line import/extensions
import SaveButton from '@/views/Cars/forms/SaveButton'

export default {
  name: 'Edit',
  components: {
    ValidationObserver,
    Public,
    License,
    Other,
    SaveButton,
  },
  data() {
    return {
      locale: 'tr',
      submitStatus: false,
    }
  },
  computed: {
    car() {
      return this.$store.getters['cars/getCar']
    },
    saveStatus() {
      return this.$store.getters['cars/getCarSaveStatus']
    },
  },
  watch: {
    car(val) {
      if (val.id) {
        this.loadedData()
      }
    },
    saveStatus(val) {
      if (val.status === true) {
        this.formData = {
          id_com_brand: null,
          id_com_location: null,
          sdate: null,
          edate: null,
          count: null,
        }
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
      this.car.submitStatus = false
    },
  },
  created() {
    this.getCar()
    localize(this.locale)
  },
  methods: {
    getCar() {
      this.$store.dispatch('cars/carView', this.$route.params.id)
    },
    loadedData() {
      if (this.car.id) {
        if (this.car.id_com_swap_brand) {
          this.$store.dispatch('swapmodels/swapmodelsList', {
            select: ['com_swap_model.id AS id', 'com_swap_model.title AS title'],
            where: {
              id_com_swap_brand: this.car.id_com_swap_brand,
            },
          })
        }
      }
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.submitStatus = true
          this.car.submitStatus = true
          this.$store.dispatch('cars/carSave', this.car)
        }
      })
    },
  },
}
</script>
